const getElementsByClassName = (nodes, className) => {
  const result = [];

  const getTableElements = (nodesArray) => {
    nodesArray.forEach((node) => {
      if (node.className.includes(className)) {
        result.push(node);
      }
    });

    const filtered = nodesArray.filter((node) => node.children.length);

    if (filtered.length) {
      const children = filtered.reduce((acc, curr) => [...acc, ...curr.children], []);
      return getTableElements(children);
    }

    return result;
  };

  return getTableElements(nodes);
};

export default getElementsByClassName;
