/**
 *
 * function calculates the maximum possible height of the links to satisfy conditions
 *
 * 130 - .sidebar "top" property (Sidebar.scss)
 * 50 - .scroll-top-button "height" property (ScrollTopButton.scss)
 * 30 - .scroll-top-button "bottom" property (ScrollTopButton.scss)
 * 30 - distance between scroll-top button and sidebar
 * 30 - .table-of-contents "margin-bottom" property (TableOfContents.scss)
 * 32 - .table-of-contents-title "line-height" (TableOfContents.scss)
 * 10 - .table-of-contents-title "margin-bottom" property (TableOfContents.scss)
 */

const getLinksMaxHeight = (sales, viewport) => {
  const salesHeight = sales ? sales.current?.offsetHeight : 0;
  const maxSidebarHeight = viewport - (130 + 50 + 30 + 30);
  const maxTableHeight = maxSidebarHeight - (salesHeight + 30);
  return maxTableHeight - (32 + 10);
};

/**
 *
 * function calculates the current height of all links
 *
 * (linksList.length - 1) * 15 - height all the links including paddings and margins between them
 * 48 - .table-of-contents-menu "padding-top + padding-bottom" properties (TableOfContents.scss)
 */
const getActualLinksHeight = (linksList) =>
  Array.from(linksList).reduce((curr, next) => curr + next.offsetHeight, 0) +
  (linksList.length - 1) * 15 +
  48;

/**
 *
 * @param sales - object = { current: React Element }
 * @param viewport - number
 * @param linksList - array of element nodes
 * @returns {{maxHeight: number, actualHeight: number}}
 */

const getLinksHeight = ({ sales, viewport, linksList }) => ({
  maxHeight: getLinksMaxHeight(sales, viewport),
  actualHeight: getActualLinksHeight(linksList),
});

export default getLinksHeight;
