/**
 *
 * @param linksWrapper - object = { current: React element }
 * function calculates the height to active link element;
 * then scrolls top to that height
 */

const scrollToActiveLink = (linksWrapper) => {
  const linksNode = linksWrapper?.current?.childNodes;
  const links = linksNode && Array.from(linksNode);

  const slicedToActive = links?.length
    ? links.slice(0, links.findIndex((el) => el.classList.contains('isActive')) + 1)
    : [];

  const scrollHeight =
    slicedToActive.length &&
    slicedToActive.reduce((curr, next, index) => index > 1 && curr + next.clientHeight, 0);

  if (linksWrapper.current) {
    linksWrapper.current.scrollTo({
      top: scrollHeight,
      behavior: 'smooth',
    });
  }
};

export default scrollToActiveLink;
