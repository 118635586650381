import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import { graphql } from 'gatsby';
import ScrollToTop from 'react-scroll-to-top';

import { useWindowSize } from 'hooks';
import { sanitize, parser, isInViewport } from 'utils';
import {
  getElementsByClassName,
  getLinksHeight,
  scrollToActiveLink,
} from 'utils/table-of-contents';
import { TWITTER_HANDLE, SITE_DOMAIN, MIN_VIEWPORT_HEIGHT } from 'globals';

import { ScrollTopIcon } from 'icons';
import { BlogTop, TableOfContents } from 'components/Blog';
import { BeInTouch } from 'components/Paragraphs/Types';
import {
  Layout,
  Paragraphs,
  SEO,
  Form,
  PopUp,
  ShareButtons,
  SalesBlock,
  Recommended,
} from 'components';

const BlogTemplate = ({
  path,
  data: {
    nodeBlog: {
      relationships: {
        field_pop_up_block: popUpData,
        field_content: paragraphs,
        field_sales_person_reference: salesInfo,
        field_image_header: headerImage,
        image: teaserImage,
        field_file,
        field_recommended_to_read,
        field_blog_category,
        field_article_author,
        field_breadcrumbs,
      },
      field_automatic_breadcrumbs,
      field_metatags: metaTags,
      field_boolean: sidebar,
      field_image,
      field_image_header,
      field_last_update,
      created,
      title,
      field_reading_time,
      field_block_title,
      body: { processed: blogBody },
    },
    allWebformWebform: {
      edges: [
        {
          node: { title: checklistFormTitle },
        },
      ],
    },
  },
  location: { pathname: siteUrl },
}) => {
  const [contentLinks, setContentLinks] = useState([]);
  const [activeIdLink, setActiveIdLink] = useState('');
  const blogRef = useRef(null);
  const paragraphRef = useRef(null);
  const sidebarRef = useRef(null);
  const tableRef = useRef(null);
  const tableLinksRef = useRef(null);
  const tableLinksWrapperRef = useRef(null);
  const salesInfoRef = useRef(null);

  const { height: viewportHeight } = useWindowSize();

  const isBigScreen = viewportHeight > MIN_VIEWPORT_HEIGHT;

  const setLinksHeight = useCallback(() => {
    if (sidebarRef.current && tableRef.current) {
      const { maxHeight, actualHeight } = getLinksHeight({
        sales: salesInfoRef,
        viewport: viewportHeight,
        linksList: tableLinksWrapperRef?.current?.childNodes,
      });

      if (actualHeight > maxHeight) {
        if (isBigScreen) {
          const innerLinksHeight = maxHeight - 48;
          tableLinksRef.current.style.maxHeight = `${innerLinksHeight}px`;
          tableLinksWrapperRef.current.style.overflowY = 'scroll';
        } else {
          tableLinksRef.current.style.maxHeight = '250px';
          tableLinksWrapperRef.current.style.overflowY = 'scroll';
        }
      }
    }
  }, [viewportHeight]);

  useEffect(() => {
    setTimeout(setLinksHeight, 800);
  }, []);

  useEffect(() => {
    setLinksHeight();
  }, [viewportHeight]);

  useEffect(() => {
    if (isBigScreen) {
      scrollToActiveLink(tableLinksWrapperRef);
    }
  }, [activeIdLink]);

  useEffect(() => {
    const blogElements = Array.from(blogRef.current.children);
    const paragraphElements = Array.from(paragraphRef.current.children);

    const tableElements = getElementsByClassName(
      [...blogElements, ...paragraphElements],
      'table-content',
    );

    const setAnchor = (element) => {
      if (element) {
        const id = `id-${element.innerText.replace(/\W/g, '-').toLocaleLowerCase()}`;
        element.setAttribute('id', id);
        setContentLinks((prevState) => [...prevState, { title: element.innerText, id }]);
      }
    };

    tableElements.forEach(setAnchor);

    const scrollHandler = () => {
      let activeId = '';

      tableElements.forEach((element) => {
        if (isInViewport(element)) {
          activeId = element.id;
        }
      });

      setActiveIdLink(activeId);
    };

    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const tableWrap = document.querySelectorAll('.table-body-scroll');

      tableWrap.forEach((item) => {
        const table = item.querySelector('table');
        // eslint-disable-next-line no-param-reassign
        item.style.height = `${table.offsetHeight}px`;
      });
    }, 800);
  }, []);

  const formattedContent = blogBody
    .split('/sites/default/files')
    .join(`${process.env.GATSBY_SSL_URL}sites/default/files`);
  const parsedBody = parser(sanitize(formattedContent));

  const blogImage = {
    image: headerImage || teaserImage || '',
    alt: (headerImage ? field_image_header.alt : field_image.alt) || 'AnyforSoft',
  };

  return (
    <Layout
      type={sidebar || salesInfo ? 'blog sidebar' : 'blog'}
      metatags={metaTags || ''}
      nodeTitle={title}
      isDefaultBreadcrumbs={field_automatic_breadcrumbs}
      customBreadcrumbs={field_breadcrumbs}
      parentBreadcrumb="/blog/"
      parentTitle="Blog"
    >
      {metaTags && (
        <SEO
          title={metaTags.title || title}
          description={metaTags.description || ''}
          keywords={metaTags.keywords || ''}
          robots={metaTags.robots}
          currentUrl={siteUrl}
          imageUrl={teaserImage.localFile?.childImageSharp.fluid.src}
        />
      )}
      <div itemScope itemType="https://schema.org/BlogPosting" className="blog-article">
        <BlogTop
          created={created}
          updated={field_last_update}
          title={title}
          readingTime={field_reading_time}
          tags={field_blog_category}
          blogImage={blogImage}
          authors={field_article_author}
        />

        <div className="blog-content">
          <div className="blog-body">
            {parsedBody && (
              <div className="text" ref={blogRef}>
                {parsedBody}
              </div>
            )}

            {paragraphs && (
              <div ref={paragraphRef}>
                {paragraphs.map((item) => (
                  <Paragraphs key={uid(item)} data={item} />
                ))}
              </div>
            )}

            <div className="share-social">
              <span>Share:</span>
              <ShareButtons
                title={title}
                url={`${SITE_DOMAIN}${path}`}
                twitterHandle={TWITTER_HANDLE}
              />
            </div>
          </div>

          {(sidebar || salesInfo) && (
            <aside className="sidebar" ref={sidebarRef}>
              {sidebar && contentLinks.length > 0 && (
                <TableOfContents
                  tableRef={tableRef}
                  tableLinksRef={tableLinksRef}
                  tableLinksWrapperRef={tableLinksWrapperRef}
                  titles={contentLinks}
                  activeIdLink={activeIdLink}
                />
              )}

              {salesInfo && (
                <SalesBlock
                  innerRef={salesInfoRef}
                  blockTitle={field_block_title}
                  salesInfo={salesInfo}
                />
              )}
            </aside>
          )}
        </div>

        <ScrollToTop smooth className="scroll-top-button" component={<ScrollTopIcon />} />

        <meta
          itemScope
          itemProp="mainEntityOfPage"
          itemType="https://schema.org/WebPage"
          itemID={`${SITE_DOMAIN}${path}`}
          content={`${SITE_DOMAIN}${path}`}
        />
        <span className="hidden" itemProp="description">
          {metaTags?.description ||
            'AnyforSoft builds professional product development teams that care. Our primary focus is to empower SMEs with digital transformation using Drupal and JS stacks.'}
        </span>
      </div>

      {field_file && (
        <div key={`checklist${Math.random()}`} className="item wrapper-checklist">
          <h3 className="form-title">{checklistFormTitle}</h3>
          <Form key="checklist" formId="checklist" file={field_file} title={title} />
        </div>
      )}

      {field_recommended_to_read && <Recommended recommended={field_recommended_to_read[0]} />}
      <BeInTouch title="Want to work with us?" buttonTitle="Let’s Talk" blueBg="blue-bg" />
      {popUpData && <PopUp dataItem={popUpData} />}
    </Layout>
  );
};

BlogTemplate.propTypes = {
  path: PropTypes.string,
  data: PropTypes.shape({
    nodeBlog: PropTypes.object,
    allWebformWebform: PropTypes.object,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default BlogTemplate;

// The $drupal_id variable here is obtained from the "context" object passed into
// the createPage() API in gatsby-node.js.
//
// Also note the use of field name aliasing in the query. This is done to
// help normalize the shape of the recipe data.
export const query = graphql`
  query BlogTemplate($drupal_id: String!) {
    nodeBlog(drupal_id: { eq: $drupal_id }) {
      ...NodeBlog
    }
    allWebformWebform(filter: { drupal_internal__id: { eq: "checklist" } }) {
      ...AllWebformWithTitle
    }
  }
`;
